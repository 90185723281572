import { render, staticRenderFns } from "./InternalChatSideBar.vue?vue&type=template&id=235b60cc&"
import script from "./InternalChatSideBar.vue?vue&type=script&lang=js&"
export * from "./InternalChatSideBar.vue?vue&type=script&lang=js&"
import style0 from "./InternalChatSideBar.vue?vue&type=style&index=0&id=235b60cc&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports